export default [
  {
    title: "Início",
    path: "/",
    sitemap: true,
  },
  {
    title: "Como participar",
    path: "/como-participar",
    sitemap: true,
  },
  {
    title: "Regulamento",
    path: "/regulamento",
    sitemap: true,
  },
  {
    title: "Login",
    path: "/login",
    sitemap: true,
  },
  {
    title: "Cadastro",
    path: "/cadastrar",
    sitemap: true,
  },
  {
    title: "Roleta",
    path: "/roleta",
    sitemap: false,
  },
  {
    title: "Meu extrato",
    path: "/meu-extrato",
    sitemap: false,
  },
  {
    title: "Meus números",
    path: "/meus-numeros",
    sitemap: false,
  },
  {
    title: "Meus descontos",
    path: "/meus-descontos",
    sitemap: false,
  },
  {
    title: "Política de privacidade",
    path: "/politica-privacidade",
    sitemap: true,
  },
  {
    title: "Dúvidas",
    path: "/duvidas",
    sitemap: true,
  },
  {
    title: "Cadastrar cupom",
    path: "/cupom",
    sitemap: false,
  },
  {
    title: "Ganhador",
    path: "/ganhador",
    sitemap: false,
  },
  {
    title: "Recuperar senha",
    path: "/esqueci-minha-senha",
    sitemap: true,
  },
  {
    title: "Fale conosco",
    path: "/fale-conosco",
    sitemap: true,
  },
  {
    title: "Ativar cadastro",
    path: "/ativar-cadastro",
    sitemap: false,
  },
  {
    title: "Início",
    path: "*",
    sitemap: false,
  },
];