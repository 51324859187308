import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import { Component as CommingSoonPage } from "./pages/ComingSoonPage";

export const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path="/em-breve" element={<CommingSoonPage />} />
    <Route path="/" element={<RootLayout />}>
      <Route lazy={() => import("./pages/HomePage")} index />
      <Route path="/como-participar" lazy={() => import("./pages/HomePage")} />
      <Route path="/regulamento" lazy={() => import("./pages/RegulationPage")} />
      <Route path="/ganhador" lazy={() => import("./pages/WinnersPage")} />
      <Route path="/login" lazy={() => import("./pages/LoginPage")} />
      <Route path="/cadastrar" lazy={() => import("./pages/RegisterPage")} />
      <Route path="/cupom/:id" lazy={() => import("./pages/RoulettePage")} />
      <Route path="/meu-extrato" lazy={() => import("./pages/CouponsPage")} />
      <Route path="/meus-numeros" lazy={() => import("./pages/CouponsPage")} />
      <Route path="/meus-descontos" lazy={() => import("./pages/DiscountsPage")} />
      <Route path="/politica-privacidade" lazy={() => import("./pages/PrivacyPolicyPage")} />
      <Route path="/duvidas" lazy={() => import("./pages/FAQPage")} />
      <Route path="/cupom" lazy={() => import("./pages/CouponRegisterPage")} />
      <Route path="/esqueci-minha-senha" lazy={() => import("./pages/PasswordRecoveryPage")} />
      <Route path="/fale-conosco" lazy={() => import("./pages/ContactPage")} />
      <Route path="/ativar-cadastro" lazy={() => import("./pages/ProfileActivation")} />
      <Route path="/nao-adulto" lazy={() => import("./pages/NotAdult")} />
      <Route path="*" lazy={() => import("./pages/HomePage")} />
    </Route>
  </>
));
